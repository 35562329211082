.form {
  padding: 30px;
  background-color: white;

  border: 1px solid #dae6ff;
  border-radius: 10px;
}

.form_info {
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1.5px solid #eaeaea;
}

.form_info > span {
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.form_fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 30px;

  gap: 10px;
}

.form_field {
  position: relative;

  padding: 20px;
  border: 1.5px solid #f3f3f3;
  border-radius: 10px;

  display: flex;
  gap: 10px;
  align-items: center;

  cursor: pointer;
  /* width: fit-content; */
}

.form_field.active {
  border-color: #0f6eff;
  background-color: rgba(231, 240, 255, 0.3);
}

.tick_icon {
  position: absolute;
  right: 10px;

  width: 25px;
  height: 25px;

  display: none;
}

.active > .tick_icon {
  display: block;
}

.field_icon {
  width: 45px;
  height: 45px;
}

.field_info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* justify-content: space-around; */
}

.field_name {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* justify-content: space-around; */
}

.field_price {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
}

.continue_button {
  padding: 15px 80px;
  background-color: #0f6eff;
  color: white;

  width: fit-content;
  border-radius: 10px;

  align-self: flex-end;
}
