.progress {
  position: relative;
  /* display: flex; */
  /* padding: 50px 50px; */
  width: fit-content;
  /* height: fit-content; */
  gap: 5px 150px;

  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* align-items: center; */
  justify-items: center;
}

.progress > span {
  font-weight: 500;
}

/* .progress_wrapper {
  position: relative;
  display: flex;
  padding: 50px 0px;
  width: fit-content;
  height: fit-content;
  gap: 150px;
} */

.progress_icon {
  padding: 15px;
  background-color: #e4eaf2;
  border-radius: 100px;
  width: fit-content;
  height: fit-content;

  border: 4px solid #f6f9ff;
  transition: 1s;
}

.complete {
  background-color: #f29a2e;
}

.progress::before {
  content: "";
  /* margin: 0 50px; */
  width: 90%;
  height: 3px;

  top: 40%;

  background-color: #ffb963;
  box-shadow: 0 0 1vw 1px #ffb963, inset 0 0 1vw 1px #ffb963;

  position: absolute;

  z-index: -1;

  transition: 1s;
  transform-origin: 0% 50%;
}

.progress::after {
  content: "";
  /* margin: 0 50px; */
  width: 90%;
  height: 3px;

  top: 40%;

  background-color: #e7eaf0;

  position: absolute;

  z-index: -2;

  transition: 1s;
  transform-origin: 0% 50%;
}

.progress_1::before {
  transform: scaleX(0.23);
}

.progress_2::before {
  transform: scaleX(0.53);
}

.progress_3::before {
  transform: scaleX(0.83);
}

.progress_4::before {
  transform: scaleX(1);
}

.progress_logo {
  fill: aqua;
}
