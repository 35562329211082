.bar {
  width: 36px;
  height: 18px;
  background-color: #dadee3;
  border-radius: 100px;

  /* align-self: center; */
}

.bar_state {
  height: 18px;
  width: 18px;
  border-radius: 100px;

  transition: transform 500ms;

  position: relative;
}

.bar_state_true {
  background-color: #0f6eff;
}

.bar_state_true > div {
  transform: translateX(115%);
  background-color: white;
  border: 1px solid #0f6eff;
}

.bar_state_false {
  background-color: #dadee3;
}

.bar_state_false > div {
  transform: translateX(0);
  background-color: white;
  border: 1px solid #dadee3;
}

.bar_state_false + .bar_state {
  color: white;
}

.bar_state_false::before {
  background-color: #dadee3 !important;
}

.bar_state_false > div::before {
  background-color: #dadee3;
}

.bar_state::before {
  position: absolute;
  content: "";

  width: 8px;
  height: 1px;
  background-color: blue;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.bar_state_true > div::after {
  position: absolute;
  content: "";

  width: 1px;
  height: 8px;
  background-color: blue;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .bar {
    position: absolute;
    right: 5vw;
  }
}
