.menu_item {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 90px;

  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}

.menu_item_logo {
  width: 25px;
}

.menu_item_text {
  font-size: 12px;
}

.active::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #1977f3;

  left: 0;
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
}
