@font-face {
  font-family: "Eudoxus Sans";
  src: url("./fonts/Eudoxus-Sans-font/EudoxusSans-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Eudoxus Sans";
  src: url("./fonts/Eudoxus-Sans-font/EudoxusSans-Bold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Eudoxus Sans";
  src: url("./fonts/Eudoxus-Sans-font/EudoxusSans-ExtraBold.ttf");
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Eudoxus Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  all: unset;
  cursor: pointer;
}

a {
  all: unset;
}

input[type="date"] {
  padding: 10px 30px;
}
