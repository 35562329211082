.list_box {
  background-color: white;
  border: 1px solid #dae6ff;
  border-radius: 10px;
  margin-top: 30px;

  padding: 30px;
  width: fit-content;
}

.form {
  display: flex;
  gap: 20px;
  align-items: center;
}

.search_bar {
  padding: 10px;
  margin-right: 300px;
  border-radius: 10px;
  border: 1.5px solid #e9e9e9;
}

.search_bar::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.dropdown_label {
  color: rgba(0, 0, 0, 0.5);
}
.dropdown {
  padding: 10px;
  border-radius: 5px;

  border: 1.5px solid #e9e9e9;
}

.campaign_table {
  margin-top: 30px;
  background-color: #f8f8f8;
  border: 1.5px solid #eaeaea;
  color: rgba(0, 0, 0, 0.5);
  padding: 10px 0px;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr 2fr 10fr 7fr 2fr 3fr 4fr 2fr 5fr 3fr;
  font-size: 15px;

  /* align-content: center; */
  /* align-items: center; */
  justify-content: center;
  justify-items: center;
}

.campaign_values {
  padding: 30px 0px;

  display: grid;
  grid-template-columns: 1fr 2fr 10fr 7fr 2fr 3fr 4fr 2fr 5fr 3fr;
  font-size: 15px;

  align-items: center;
  justify-items: center;

  border-top: 1px solid #eaeaea;
}
