.side_menu {
  height: 120%;
  width: 90px;
  background-color: #001738;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 70px;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.zocket_logo {
  width: 50px;
  height: 50px;
  margin-top: 50px;
}

.nav_items {
  display: flex;
  flex-direction: column;
  width: 90px;

  color: white;
  gap: 20px;
}
