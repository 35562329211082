.form {
  padding: 30px;
  background-color: white;

  border: 1px solid #dae6ff;
  border-radius: 10px;
  width: fit-content;

  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form_info {
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1.5px solid #eaeaea;
}

.form_info > span {
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.form_fields {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form_index {
  padding: 5px 10px;
  background-color: #0f6eff;
  font-size: 10px;
  border-radius: 100px;
  color: white;
}

.form_value {
  padding: 5px 10px;
  text-decoration: underline;
  text-align: center;
  font-weight: 400;
}

.budget_date_fields {
  padding-left: 30px;
  margin-left: 11px;
  padding-top: 30px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border-left: 1.5px solid rgba(0, 0, 0, 0.16);
}

.budget_timeline {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sub_title {
  color: #606060;
  font-size: 13px;
}

.date_range {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.budget_range {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.budget_range_input {
  width: 700px;
  border: 1.5px solid #e9e9e9;
}

.date_input {
  border: 1.5px solid #e9e9e9;
  border-radius: 10px;
}
.location_input {
  padding: 10px 10px;
  border: 1.5px solid #e9e9e9;
  border-radius: 10px;
}

.location_input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1; /* Firefox */
}
